import { createRouter, createWebHashHistory } from 'vue-router'
import routes from './routes'
import { getToken } from '@/plugins/cookie/auth'
import { getStorage } from '@/plugins/storage'

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  const token = getToken()
  const userInfo = JSON.parse(getStorage('wallet_user') || '{}')
  const exclodes = ['/terms', '/privacy', '/transition']
  if (!token && to.name !== 'login' && !exclodes.includes(to.path)) {
    next({
      name: 'login',
      query: {
        backUrl: `${window.location.origin}/#${to.fullPath}`,
      },
    })
  } else if (token && to.name === 'login' && userInfo.phone) {
    // 先跳转首页，后面根据bind情况再说
    next({
      name: '/',
    })
  } else if (token && to.name === 'login' && !userInfo.phone) {
    next({
      name: 'bind',
      query: {
        backUrl: `${window.location.origin}/#${to.fullPath}`,
      },
    })
  } else {
    next()
  }
})

export default router
