import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/login/index.vue'),
    meta: {
      title: '登录',
    },
  },
  {
    path: '/transition',
    name: 'transition',
    component: () => import(/* webpackChunkName: "transition" */ '@/views/login/Transition.vue'),
    meta: {
      title: '回调过渡页',
    },
  },
  {
    path: '/bind',
    name: 'bind',
    component: () => import(/* webpackChunkName: "bind" */ '@/views/bind/index.vue'),
    meta: {
      title: '绑定手机号',
    },
  },
  {
    path: '/',
    name: '/',
    component: () => import(/* webpackChunkName: "home" */ '../views/home/index.vue'),
    meta: {
      title: '首页',
    },
  },
  {
    path: '/details',
    name: 'details',
    component: () => import(/* webpackChunkName: "details" */ '../views/details/index.vue'),
    meta: {
      title: '版权详情',
    },
  },
  {
    path: '/assets',
    name: 'assets',
    component: () => import(/* webpackChunkName: "assets-list" */ '../views/transfer/AssetsList.vue'),
    meta: {
      title: '资产列表',
    },
  },
  {
    path: '/transfer',
    name: 'transfer',
    component: () => import(/* webpackChunkName: "about" */ '../views/transfer/index.vue'),
    meta: {
      title: '转账',
    },
  },
  {
    path: '/receive',
    name: 'receive',
    component: () => import(/* webpackChunkName: "receive" */ '../views/transfer/Receive.vue'),
    meta: {
      title: '转账领取',
    },
  },
  {
    path: '/detailed',
    name: 'detailed',
    component: () => import(/* webpackChunkName: "detailed" */ '../views/detailed/index.vue'),
    meta: {
      title: '明细',
    },
  },
  {
    path: '/my',
    name: 'my',
    component: () => import(/* webpackChunkName: "my" */ '../views/my/index.vue'),
    meta: {
      title: '我的',
    },
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import(/* webpackChunkName: "terms" */ '../views/terms/index.vue'),
    meta: {
      title: '用户协议',
    },
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "privacy" */ '../views/privacy/index.vue'),
    meta: {
      title: '隐私政策',
    },
  },
  {
    path: '/help',
    name: 'help',
    component: () => import(/* webpackChunkName: "help" */ '../views/help/index.vue'),
    meta: {
      title: '帮助',
    },
  },
  {
    path: '/debugger',
    name: 'debugger',
    component: () => import(/* webpackChunkName: "help" */ '../views/debugger/index.vue'),
    meta: {
      title: '测试',
    },
  },
]

export default routes
