<template>
  <router-view />
</template>

<style lang="less">
* {
  margin: 0;
  padding: 0;
}
html,
body {
  // height: 100%;
  // overflow: auto;
  min-height: 100vh;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
  font-size: 100px;
  background: linear-gradient(180deg, #5f627d, #313347) no-repeat;
  -webkit-text-size-adjust: 100% !important;
  text-size-adjust: 100% !important;
  -moz-text-size-adjust: 100% !important;
}
body {
  font-family: 'PingFangSC-Regular', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
  font-size: 0.12rem;
  font-weight: 400;
}
a {
  text-decoration: none;
}
ul,
li {
  list-style: none;
}
img[src=''],
img:not([src]) {
  opacity: 0;
}
.normal_btn {
  width: 3.27rem;
  height: 0.48rem;
  text-align: center;
  line-height: 0.48rem;
  border-radius: 0.1rem;
  margin: 0 auto;
  margin-bottom: 0.36rem;
  font-size: 0.14rem;
  font-weight: 600;
  border: none;
}
.pullup-tips {
  padding: 20px;
  text-align: center;
  color: #999;
}
.van-toast {
  background: rgba(51, 53, 73, 0.9) !important;
  padding: 0.37rem 0.26rem !important;
  font-size: 0.14rem !important;
  font-weight: 600;
}
</style>
