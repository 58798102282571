import Cookies from 'js-cookie'

const TokenKey: string = process.env.VUE_APP_TOKEN_KEY

// 获取token
export const getToken = (): string | undefined => Cookies.get(TokenKey)

// 设置token
export const setToken = (token: string, expires = 7): any => Cookies.set(TokenKey, token, { expires })

// 移除token
export const removeToken = (): any => Cookies.remove(TokenKey)
