import { createStore } from 'vuex'
import { setStorage, getStorage } from '@/plugins/storage'
import { UNIT_PERCENTAGE } from '@/config/const'

export default createStore({
  state: {
    unit: getStorage('unit') || UNIT_PERCENTAGE,
    loading: true,
  },
  mutations: {
    CHANGE_UNIT_TYPE: (state, payload) => {
      state.unit = payload
      setStorage('unit', payload)
    },
    CHANGE_LOADING: (state, payload) => {
      state.loading = payload
    },
  },
  actions: {},
  modules: {},
})
